<template>
  <div class="h-100">
    <b-overlay
      id="overlayBulkOperationsSidebar"
      style="min-height: 50%"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <div v-if="nodes.length > 0">
        {{ nodes.length }} selected
        <b-tabs fill class="">
          <b-tab title="Associations">
            <template #title>
              <feather-icon icon="LinkIcon" />
              <span>Associated Entities</span>
            </template>

            <!-- Requirements -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top="'Associate Requirements'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-requirements')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-requirements
                    variant="flat-primary"
                    block
                  >
                    Requirements
                    <b-badge class="float-right" :variant="requirements.length ? 'info' : 'muted'">
                      {{ requirements.length ? requirements.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-card-body>
                <b-card-text v-if="requirements.length > 0">
                  <b-table
                    id="tblBehaviourNodeRequirements"
                    :items="selected_requirements"
                    :fields="tblRequirementsFields"
                    thead-class="tblHeaderHidden"
                    hover
                  >
                    <template v-slot:cell(name)="data">
                      <router-link
                        :to="{
                          name: 'legacy_requirements_table',
                          params: { specId: data.item.spec_id },
                          query: { focus: data.item.id },
                        }"
                      >
                        {{ data.item.name }}
                        <font-awesome-icon
                          :icon="['fas', 'external-link-alt']"
                          class="ml-50 font-small-2"
                        />
                      </router-link>
                    </template>
                  </b-table>
                </b-card-text>
                <b-card-text v-else>
                  Click <feather-icon
                    icon="LinkIcon"
                  /> to associate {{ nodes.length }} nodes to <span class="font-weight-bolder">Requirements</span>.
                </b-card-text>
              </b-card-body>
            </b-card>
            <!-- ./Requirements -->

            <!-- Issues -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top="'Associate Issues'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-issues')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover.top="'New Issue'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('create-and-allocate-issue-bulk-modal')"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-issues
                    variant="flat-primary"
                    block
                  >
                    Issues
                    <b-badge class="float-right" :variant="issues.length ? 'info' : 'muted'">
                      {{ issues.length ? issues.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-card-body>
                <b-card-text v-if="issues.length > 0">
                  <b-table
                    id="tblBehaviourNodeIssues"
                    :items="selected_issues"
                    :fields="tblIssuesFields"
                    thead-class="tblHeaderHidden"
                    hover
                  >
                    <template v-slot:cell(name)="data">
                      <router-link
                        :to="{
                          name: 'issue_details',
                          query: { id: data.item.id },
                        }"
                      >
                        <span v-if="data.item.display_id">{{ data.item.display_id }} - {{ data.item.name }}</span>
                        <span v-else-if="data.item.short_id">_{{ data.item.short_id }}_ - {{ data.item.name }}</span>
                        <span v-else>{{ data.item.id }} - {{ data.item.name }}</span>
                        <font-awesome-icon
                          :icon="['fas', 'external-link-alt']"
                          class="ml-50 font-small-2"
                        />
                      </router-link>
                    </template>
                  </b-table>
                </b-card-text>
                <b-card-text v-else>
                  Click <feather-icon
                    icon="LinkIcon"
                  /> to associate {{ nodes.length }} nodes to <span class="font-weight-bolder">Issues</span>.
                </b-card-text>
              </b-card-body>
            </b-card>
            <!-- ./Issues -->

            <!-- Tests -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top="'Associate Tests'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-tests')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-tests
                    variant="flat-primary"
                    block
                  >
                    Test Cases
                    <b-badge class="float-right" :variant="tests.length ? 'info' : 'muted'">
                      {{ tests.length ? tests.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-card-body>
                <b-card-text v-if="tests.length > 0">
                  <b-table
                    id="tblBehaviourNodeTests"
                    :items="selected_tests"
                    :fields="tblTestsFields"
                    thead-class="tblHeaderHidden"
                    hover
                  >
                    <template v-slot:cell(name)="data">
                      <router-link
                        :to="{
                          name: 'model_test_focus',
                          query: { testId: data.item.id },
                        }"
                      >
                        <span v-if="data.item.display_id">{{ data.item.display_id }} - {{ data.item.name }}</span>
                        <span v-else-if="data.item.short_id">_{{ data.item.short_id }}_ - {{ data.item.name }}</span>
                        <span v-else>{{ data.item.id }} - {{ data.item.name }}</span>
                        <font-awesome-icon
                          :icon="['fas', 'external-link-alt']"
                          class="ml-50 font-small-2"
                        />
                      </router-link>
                    </template>
                  </b-table>
                </b-card-text>
                <b-card-text v-else>
                  Click <feather-icon
                    icon="LinkIcon"
                  /> to associate {{ nodes.length }} nodes to <span class="font-weight-bolder">Test Cases</span>.
                </b-card-text>
              </b-card-body>
            </b-card>

            <!-- Objectives -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top="'Associate Objectives'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-objectives')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-requirements
                    variant="flat-primary"
                    block
                  >
                    Objectives
                    <b-badge class="float-right" :variant="objectives.length ? 'info' : 'muted'">
                      {{ objectives.length ? objectives.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-card-body>
                <b-card-text v-if="objectives.length > 0">
                  <b-table
                    id="tblBehaviourNodeObjectives"
                    :items="selected_objectives"
                    :fields="tblObjectivesFields"
                    thead-class="tblHeaderHidden"
                    hover
                  >
                    <template v-slot:cell(name)="data">
                      <router-link
                        :to="{
                          name: 'domain_ontology_focus',
                          query: { focus: data.item.id },
                        }"
                      >
                        {{ data.item.name }}
                        <font-awesome-icon
                          :icon="['fas', 'external-link-alt']"
                          class="ml-50 font-small-2"
                        />
                      </router-link>
                    </template>
                  </b-table>
                </b-card-text>
                <b-card-text v-else>
                  Click <feather-icon
                    icon="LinkIcon"
                  /> to associate {{ nodes.length }} nodes to <span class="font-weight-bolder">Objectives</span>.
                </b-card-text>
              </b-card-body>
            </b-card>
            <!-- ./Objectives -->

            <!-- Functions -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top="'Associate Functions'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-functions')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-requirements
                    variant="flat-primary"
                    block
                  >
                    Functions
                    <b-badge class="float-right" :variant="functions.length ? 'info' : 'muted'">
                      {{ functions.length ? functions.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-card-body>
                <b-card-text v-if="functions.length > 0">
                  <b-table
                    id="tblBehaviourNodeFunctions"
                    :items="selected_functions"
                    :fields="tblFunctionFields"
                    thead-class="tblHeaderHidden"
                    hover
                  >
                    <template v-slot:cell(name)="data">
                      <router-link
                        :to="{
                          name: 'domain_ontology_focus',
                          query: { focus: data.item.id },
                        }"
                      >
                        {{ data.item.name }}
                        <font-awesome-icon
                          :icon="['fas', 'external-link-alt']"
                          class="ml-50 font-small-2"
                        />
                      </router-link>
                    </template>
                  </b-table>
                </b-card-text>
                <b-card-text v-else>
                  Click <feather-icon
                    icon="LinkIcon"
                  /> to associate {{ nodes.length }} nodes to <span class="font-weight-bolder">Functions</span>.
                </b-card-text>
              </b-card-body>
            </b-card>
            <!-- ./Functions -->

            <!-- Enablers -->
            <b-card no-body class="mb-1 border">
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button-group class="w-100">
                  <b-button
                    v-b-tooltip.hover.top="'Associate Enablers'"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    class="btn-icon"
                    variant="flat-success"
                    @click="$bvModal.show('associator-generic-enablers')"
                  >
                    <feather-icon
                      icon="LinkIcon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-toggle.context-requirements
                    variant="flat-primary"
                    block
                  >
                    Enablers
                    <b-badge class="float-right" :variant="enablers.length ? 'info' : 'muted'">
                      {{ enablers.length ? enablers.length : '-' }}
                    </b-badge>
                  </b-button>
                </b-button-group>
              </b-card-header>
              <b-card-body>
                <b-card-text v-if="enablers.length > 0">
                  <b-table
                    id="tblBehaviourNodeEnablers"
                    :items="selected_enablers"
                    :fields="tblEnablerFields"
                    thead-class="tblHeaderHidden"
                    hover
                  >
                    <template v-slot:cell(name)="data">
                      <router-link
                        :to="{
                          name: 'domain_ontology_focus',
                          query: { focus: data.item.id },
                        }"
                      >
                        {{ data.item.name }}
                        <font-awesome-icon
                          :icon="['fas', 'external-link-alt']"
                          class="ml-50 font-small-2"
                        />
                      </router-link>
                    </template>
                  </b-table>
                </b-card-text>
                <b-card-text v-else>
                  Click <feather-icon
                    icon="LinkIcon"
                  /> to associate {{ nodes.length }} nodes to <span class="font-weight-bolder">Enablers</span>.
                </b-card-text>
              </b-card-body>
            </b-card>
            <!-- ./Enablers -->
          </b-tab>
        </b-tabs>
      </div>
    </b-overlay>

    <associator-generic
      name="Functions"
      suffix=""
      :associated-items="functions"
      :all-items="fn_options"
      @associated="associateFunctions"
    />
    <associator-generic
      name="Enablers"
      suffix=""
      :associated-items="enablers"
      :all-items="enabler_options"
      @associated="associateEnablers"
    />
    <associator-generic
      name="Objectives"
      suffix=""
      :associated-items="objectives"
      :all-items="objective_options"
      @associated="associateObjectives"
    />
    <associator-generic
      name="Requirements"
      suffix=""
      :associated-items="requirements"
      :all-items="req_options"
      @associated="associateRequirements"
    />
    <associator-generic
      name="Issues"
      suffix=""
      :associated-items="issues"
      :all-items="issue_options"
      @associated="associateIssues"
    />
    <associator-generic
      name="Notes"
      suffix=""
      :associated-items="notes"
      :all-items="notes_options"
      @associated="associateNotes"
    />
    <associator-generic
      name="Tests"
      suffix=""
      :associated-items="tests"
      :all-items="test_options"
      @associated="associateTestCases"
    />
    <associate-issues-create
      :issues-create-id="'create-and-allocate-issue-bulk-modal'"
      @associateIssue="associateNewIssue"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AssociatorGeneric from '@/components/AssociatorModals/DPAssociatorGeneric.vue'
import AssociateIssuesCreate from '@/components/AssociatorModals/AssociateIssuesCreate.vue'

export default {
  components: {
    AssociatorGeneric,
    AssociateIssuesCreate,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    sideMenuDataLoading: {
      type: Boolean,
      required: true,
    },
    nodes: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      tblRequirementsFields: [
        { key: 'object_text', label: 'Requirements' },
      ],
      tblIssuesFields: [
        { key: 'name', label: 'Issue' },
      ],
      tblNotesFields: [
        { key: 'text', label: 'Note' },
      ],
      tblObjectivesFields: [
        { key: 'name', label: 'Objective' },
      ],
      tblFunctionFields: [
        { key: 'name', label: 'Function' },
      ],
      tblEnablerFields: [
        { key: 'name', label: 'Component' },
      ],
      tblTestsFields: [
        { key: 'name', label: 'Test Name' },
        { key: 'latest_result', label: 'Status' },
      ],
      // Form Repeater
      objects: [],
      nextObjectId: 0,
      nextPropId: 0,
      // Associators
      requirements: [],
      issues: [],
      functions: [],
      objectives: [],
      tests: [],
      enablers: [],
      notes: [],
      selected_req: [],
      selected_issue: [],
      properties: [],
      aveUsersExpected: '',
      aveUsersActual: '',
      peakUsersExpected: '',
      peakUsersActual: '',
      aveTPSExpected: '',
      aveTPSActual: '',
      aveTPSUnit: 'TPS',
      peakTPSExpected: '',
      peakTPSActual: '',
      peakTPSUnit: 'TPS',
      probability: 1.0,

      // For associators
      fn_options: [],
      req_options: [],
      enabler_options: [],
      objective_options: [],
      issue_options: [],
      test_options: [],
      notes_options: [],

      // objects
      all_requirements: [],
      selected_requirements: [],
      all_functions: [],
      selected_functions: [],
      all_enablers: [],
      selected_enablers: [],
      all_objectives: [],
      selected_objectives: [],
      all_issues: [],
      selected_issues: [],
      all_tests: [],
      selected_tests: [],
    }
  },
  computed: {
    ...mapState({
      selected_bt: state => state.behaviours.selectedBehaviourTree,
    }),
    ...mapGetters({
      behaviourOperators: 'constants/behaviourOperators',
    }),
  },
  watch: {
    nodes() {
      this.reset()
    },
    functions() {
      this.selected_functions = this.all_functions.filter(x => this.functions.includes(x.id))
    },
    enablers() {
      this.selected_enablers = this.all_enablers.filter(x => this.enablers.includes(x.id))
    },
    objectives() {
      this.selected_objectives = this.all_objectives.filter(x => this.objectives.includes(x.id))
    },
    requirements() {
      this.selected_requirements = this.all_requirements.filter(x => this.requirements.includes(x.id))
    },
    issues() {
      this.selected_issues = this.all_issues.filter(x => this.issues.includes(x.id))
    },
    tests() {
      this.selected_tests = this.all_tests.filter(x => this.tests.includes(x.id))
    },
  },
  mounted() {
    this.reset()
  },
  methods: {
    routeToRequirement(requirementId) {
      this.$router.push({
        name: 'legacy_requirements_table',
        query: {
          focus: requirementId,
        },
      })
    },
    routeToNodeInNewBT(bt) {
      this.$store.dispatch('behaviours/selectBehaviourTree', bt)
    },
    routeToOntology(node) {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          params: { focus: node },
        },
      )
    },
    routeToIssue(issue) {
      this.$store.dispatch('issues/selectIssue', issue.id)
        .then(() => {
          this.$router.push(
            {
              name: 'issue_details',
              params: { id: issue.id },
            },
          )
        })
    },
    routeToTest(test) {
      this.$router.push(
        {
          name: 'model_test_focus',
          params: { testId: test.id },
        },
      )
    },
    fetchAllRequirements() {
      this.$store.dispatch('requirements/getRequirementsSimple')
        .then(() => {
          if (!this.$store.state.requirements.requirements) return
          this.all_requirements = this.$store.state.requirements.requirements
          this.req_options = this.all_requirements.map(item => ({
            value: { id: item.id },
            text: `${item.display_id} - ${item.text}`,
          }))
        })
        .catch(r => {
          console.error(`[Bulk] fetchAllReqs failed - ${r}`)
        })
    },
    reset() {
      const modelId = this.$store.state.model.id
      this.requirements = []
      this.issues = []
      this.functions = []
      this.objectives = []
      this.tests = []
      this.enablers = []
      this.notes = []
      this.aveUsersExpected = ''
      this.aveUsersActual = ''
      this.peakUsersExpected = ''
      this.peakUsersActual = ''
      this.aveTPSExpected = ''
      this.aveTPSActual = ''
      this.aveTPSUnit = 'TPS'
      this.peakTPSExpected = ''
      this.peakTPSActual = ''
      this.peakTPSUnit = 'TPS'
      this.probability = 1.0
      this.properties = []

      const params = { model: this.$store.state.model.id }
      this.$store.dispatch('domainModel/getComponents')
      // Get all functions
      this.$http
        .get('/api/v2/domain_model/get_components_simple', { params })
        .then(({ data }) => {
          this.all_functions = data.filter(c => c.labels.some(label => ['Function', 'Capability', 'Activity'].includes(label)))
          this.fn_options = this.all_functions.map(item => ({
            value: { id: item.id },
            text: item.name,
          }))
          this.enabler_options = data.map(item => ({
            value: { id: item.id },
            text: item.name,
          }))
          this.all_objectives = data.filter(c => c.labels.some(label => ['Objective', 'Benefit'].includes(label)))
          this.objective_options = this.all_objectives.map(item => ({
            value: { id: item.id },
            text: item.name,
          }))
        })
      this.fetchAllRequirements()
      this.$http
        .get('/api/v2/issues', { params: { model: modelId } })
        .then(({ data }) => {
          this.all_issues = data
          this.issue_options = this.all_issues.map(item => ({
            value: { id: item.id },
            text: item.display_id ? `${item.display_id} - ${item.name}` : `${item.short_id} - ${item.name}`,
          }))
        })
      const testParams = {
        model: this.$store.state.model.id,
        simple: true,
      }
      this.$http
        .get('/api/v2/tests', { params: testParams })
        .then(({ data }) => {
          this.all_tests = data
          this.test_options = this.all_tests.map(item => ({
            value: {
              id: item.id,
              toSortBy: item.ref_id,
            },
            text: `${item.ref_id} - ${item.name}`,
          }))
        })
    },
    updateProperties(evt) {
      const params = {}
      params.model = this.$store.state.model.id
      params.aveTPSExpected = this.aveTPSExpected
      params.aveTPSActual = this.aveTPSActual
      params.aveTPSUnit = this.aveTPSUnit
      params.peakTPSExpected = this.peakTPSExpected
      params.peakTPSActual = this.peakTPSActual
      params.peakTPSUnit = this.peakTPSUnit
      params.aveUsersExpected = this.aveUsersExpected
      params.aveUsersActual = this.aveUsersActual
      params.peakUsersExpected = this.peakUsersExpected
      params.peakUsersActual = this.peakUsersActual
      params.probability = this.probability
      params.properties = this.properties
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_properties', params)
        .then(({ data }) => {
        })
    },
    associateRequirements(toAssoc) {
      this.requirements = toAssoc
      const params = {}
      params.model = this.$store.state.model.id
      params.requirements = this.requirements
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_requirements', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.requirements.length === 1 ? '1 requirement' : `${this.requirements.length} requirements`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Requirements Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    associateIssues(toAssoc) {
      this.issues = toAssoc
      const params = {}
      params.model = this.$store.state.model.id
      params.issues = this.issues
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_issues', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.issues.length === 1 ? '1 issue' : `${this.issues.length} issues`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Issues Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    associateNewIssue(issue) {
      this.all_issues.push(issue)
      this.issues = [issue.id]
      const params = {}
      params.model = this.$store.state.model.id
      params.issues = this.issues
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_issues', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.issues.length === 1 ? '1 issue' : `${this.issues.length} issues`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Issues Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    associateObjectives(toAssoc) {
      this.objectives = toAssoc
      const params = {}
      params.model = this.$store.state.model.id
      params.objectives = this.objectives
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_objectives', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.objectives.length === 1 ? '1 objective' : `${this.objectives.length} objectives`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Objectives Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    associateEnablers(toAssoc) {
      this.enablers = toAssoc
      const params = {}
      params.model = this.$store.state.model.id
      params.enablers = this.enablers
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_enablers', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.enablers.length === 1 ? '1 enabler' : `${this.enablers.length} enablers`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Enablers Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    associateTestCases(toAssoc) {
      this.tests = toAssoc
      const params = {}
      params.model = this.$store.state.model.id
      params.tests = this.tests
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_tests', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.tests.length === 1 ? '1 test case' : `${this.tests.length} test cases`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Test Cases Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    associateFunctions(toAssoc) {
      this.functions = toAssoc
      const params = {}
      params.model = this.$store.state.model.id
      params.functions = this.functions
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_functions', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.functions.length === 1 ? '1 function' : `${this.functions.length} functions`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Functions Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },
    associateNotes(toAssoc) {
      this.notes = toAssoc
      const params = {}
      params.model = this.$store.state.model.id
      params.notes = this.notes
      params.nodes = this.nodes
      this.$http.post('/api/v2/behaviour/bulk_associate_notes', params)
        .then(() => {
          const nodeText = this.nodes.length === 1 ? '1 node' : `${this.nodes.length} nodes`
          const associatedText = this.notes.length === 1 ? '1 note' : `${this.notes.length} notes`
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notes Associated',
              text: `Linked ${associatedText} with ${nodeText}`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
    },

    // -- Utility -- //
    repeatProperty() {
      this.properties.push({
        id: this.nextPropId += 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeProperty(index) {
      this.properties.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>

<style lang="scss">
.tblHeaderHidden {
    display: none;
}

tr[id^='tblBehaviourNodeRequirements__details_'] > td {
  padding-top: 0;
  padding-right: 0;
}
</style>
