<template>
  <div
    class="colour-key vertical-key px-0"
  >
    <p class="font-weight-bold px-75 mb-25">
      Colour Legend
    </p>
    <span :class="`colour-key-all ${outerPrefix}-${selectedColourBy}`">
      <span
        v-for="n in currentSet.items"
        :key="n.name || n"
        :class="`colour-key-item ${currentSet.prefix}${(n.name || n).replace(/\W/g, '').toLocaleLowerCase()}`"
        @click="clickColourKeyItem(n.name || n)"
        v-b-popover.hover.right="n.explanation || ''"
      >
        {{ getText(n.description || n) }}
      </span>
    </span>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'
import Vue from 'vue'

const keysets = [
  {
    name: 'testedness',
    prefix: 'bn-test-',
    items: [
      'Passed',
      'Failed',
      'Inconclusive',
      'Partial',
      'No Test',
    ],
  },
  {
    name: 'validity',
    prefix: 'bn-',
    items: [
      'Valid',
      'Minor',
      'Implied',
      'Invalid',
    ],
  },
  {
    name: 'node',
    prefix: 'bn-',
    items: [
      'Condition',
      'Event',
      'GuardedEvent',
      'State',
      'Input',
      'Output',
      'Assertion',
      'Quantification',
      'Quantity',
      'Function',
    ],
  },
  {
    name: 'coverage',
    prefix: 'bn-',
    items: [
      'In Spec',
      'Not in Spec',
    ],
  },
  {
    name: 'search',
    prefix: 'bn-',
    items: [
      'Found',
      'NoMatch',
    ],
  },
  {
    name: 'enablement_trl',
    prefix: 'bn-',
    items: [
      'Unevaluated',
      'Research',
      'Concept',
      'Prototype',
      'Qualified',
      'Proven',
    ],
  },
  {
    name: 'enablement_os',
    prefix: 'bn-',
    items: [
      'No Enablers',
      'Unevaluated',
      'Failed / Non-operational',
      'Degraded (Significant)',
      'Degraded (Minor)',
      'Fully Operational',
    ],
  },
  {
    name: 'node',
    prefix: 'ont-',
    items: [
      'Node',
      'Abstract',
      'Header',
    ],
  },
  {
    name: 'buildconfig',
    prefix: 'ont-bc-',
    items: [
      {
        name: 'none',
        description: 'Not in Config',
        explanation: 'These entities and their immediate children are not in any of the selected configurations',
      },
      {
        name: 'Partial',
        description: 'Partial',
        explanation: 'These entities have some immediate children that are in at least one of the selected configurations',
      },
      {
        name: 'Full',
        description: 'Full',
        explanation: 'These entities or all of their immediate children are in at least one of the selected configurations',
      },
    ],
  },
]
export default Vue.extend({
  name: 'BehaviourColourKey',
  props: {
    testPercents: { type: Object },
    selectedColourBy: { type: String, required: true },
    outerPrefix: { type: String, default: () => 'bn' },
  },
  setup(props, { emit }) {
    const currentSet = computed(() => keysets.find(ks => ks.name === props.selectedColourBy && ks.prefix.startsWith(props.outerPrefix)))

    function getText(n) {
      if (props.selectedColourBy === 'testedness' && props.testPercents) {
        const tp = props.testPercents
        const mappct = [['Passed', `${n} - ${tp.passPercentage}%`],
          ['Inconclusive', `Not Run - ${tp.noRunPercentage}%`],
          ['Partial', `${n} - ${tp.partialPercentage}%`],
          ['Failed', `${n} - ${tp.failPercentage}%`],
          ['No Test', `N/A - ${tp.naPercentage}%`]]
        return mappct.find(x => x[0] === n)[1]
      }
      if (props.selectedColourBy === 'search') {
        if (n === 'NoMatch') {
          return 'No Matches'
        }
      }
      if (props.selectedColourBy === 'node') {
        if (n.toLowerCase() === 'guardedevent') {
          return 'Guarded Event'
        }
      }
      return n
    }

    function clickColourKeyItem(keyName) {
      emit('item-clicked', keyName)
    }

    return {
      currentSet,
      getText,
      clickColourKeyItem,
    }
  },
})
</script>
<style scoped lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu';

.colour-key {
  display: inline-block;
  padding-left: 0.25rem;
  padding-bottom: 0 !important;
  height: 2rem;
  overflow: hidden;
  border-bottom-right-radius: 0.428rem;
  border-top-right-radius: 0.428rem;
  background: $card-bg;

  .colour-key-all {
    display: inline-block;
    border: none;

    .colour-key-item {
      display: inline-block;
      padding: 0.125rem;
      color: white !important;
      text-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.8);
      font-weight: normal;
    }
  }
}

// change the all display:s to block (no inline)
// and position: relative to get horizontal key (that runs out of room)

.colour-key.vertical-key {
  display: block;
  height: revert;
  background: $body-bg;
  position: absolute;
  padding: 0.5rem;
  left: 0;
  bottom: 15rem;
  z-index: 99;

  .colour-key-all {
    display: block;

    .colour-key-item {
      display: block;
      padding: 0.5rem;
    }
  }
}

@media (max-height: 1124px) {
  .colour-key.vertical-key {
    padding: 0.125rem;
    bottom: 15rem;
    font-size: 0.8rem;
    .colour-key-all {
      .colour-key-item {
        padding: 0.125rem;
        font-size: smaller;
      }
    }
  }
}

@media (min-height: 1124px) {
  .colour-key.vertical-key {
    padding: 0.5rem;
    bottom: 15rem;
    font-size: 1rem;
    .colour-key-all {
      .colour-key-item {
        padding: 0.5rem;
        font-size: small;
      }
    }
  }
}

body.dark-layout {
  .colour-key {
    background: $theme-dark-card-bg;
  }
}
</style>
