import { shapes, util } from '@clientio/rappid';
import * as joint from "@clientio/rappid";
import { behaviourNodeStyleDefinitions } from "@/views/Behaviour/JointJSGraph/behaviourNode";
import { createDisplayNameHtml, buildNodeCss } from "@/views/Behaviour/JointJSGraph/behaviourJointJSFunctions";
import _ from 'lodash';
const markup = [
    {
        tagName: 'rect',
        selector: 'shape',
    },
    {
        tagName: 'rect',
        selector: 'body',
    },
    {
        tagName: 'foreignObject',
        selector: 'bodyText',
        children: [
            {
                tagName: 'div',
                namespaceURI: 'http://www.w3.org/1999/xhtml',
                selector: 'bodyTextContent',
                class: 'nodeText',
                style: {
                    height: '100%',
                    font: '16px',
                    'font-weight': 'bold',
                    padding: '2rem 2.5rem 2rem 2rem',
                    'text-align': 'center',
                    'overflow-wrap': 'anywhere',
                },
            }
        ]
    },
    {
        tagName: 'text',
        selector: 'bodySymbolLeft',
    },
    {
        tagName: 'text',
        selector: 'bodySymbolRight',
    },
    {
        tagName: 'text',
        selector: 'header',
    },
    {
        tagName: 'text',
        selector: 'operator',
    },
    {
        tagName: 'text',
        selector: 'text',
    },
    {
        tagName: 'circle',
        selector: 'validityBadge',
    },
    {
        tagName: 'text',
        selector: 'validityText',
    },
    {
        tagName: 'rect',
        selector: 'probabilityBadge',
    },
    {
        tagName: 'text',
        selector: 'probabilityText',
    },
];
// noinspection JSAnnotator
export class CustomNodeShape extends shapes.basic.Rect {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.CustomNodeShape',
            size: { width: 300, height: 125 },
            attrs: {
                body: {
                    width: 'calc(w)',
                    height: 'calc(h)',
                    rx: 10,
                    ry: 10,
                    strokeWidth: 2,
                    stroke: 'black',
                    magnet: true
                },
                header: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.15*h)',
                    fontSize: 18,
                    fill: 'black',
                    fontWeight: 'bolder',
                },
                operator: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'right',
                    x: 'calc(w-24)',
                    y: 24,
                    fontSize: 20,
                    fill: 'black',
                },
                bodyTextContent: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'middle',
                    x: 'calc(0.5*w)',
                    y: 'calc(0.5*h)',
                    fontSize: 14,
                    fill: 'black',
                },
                bodySymbolLeft: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'left',
                    x: 'calc(0.03*w)',
                    y: 'calc(0.5*h)',
                    fontSize: 20,
                    fill: 'black',
                    fontWeight: 'bolder',
                },
                bodySymbolRight: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'end',
                    dx: 'calc(0.97*w)',
                    y: 'calc(0.5*h)',
                    fontSize: 20,
                    fill: 'black',
                    fontWeight: 'bolder',
                },
                bodyText: {
                    width: 'calc(w-12)',
                    height: 'calc(h-12)',
                    x: 6,
                    y: 6
                },
                validityBadge: {
                    cx: 'calc(0.07*w)',
                    cy: 'calc(0.15*h)',
                    r: 15,
                    fill: 'black',
                },
                validityText: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'middle',
                    dx: 'calc(0.07*w)',
                    y: 'calc(0.15*h)',
                    fontSize: 18,
                    fill: 'black',
                    fontWeight: 'bolder',
                },
                probabilityBadge: {
                    width: 80,
                    height: 24,
                    x: 'calc(w - 88)',
                    y: 'calc(h - 28)',
                    rx: 3,
                    fill: 'black',
                    'fill-opacity': '50%',
                },
                probabilityText: {
                    textVerticalAnchor: 'middle',
                    textAnchor: 'middle',
                    fontSize: 14,
                    stroke: 'white',
                    fill: 'red',
                    class: 'bt-probability-text',
                    fontWeight: 'lighter',
                    x: 'calc(w - 51)',
                    y: 'calc(h - 16)',
                }
            },
        };
    }
    markup = markup;
}
const markup2 = _.cloneDeep(markup);
markup2.find(s => s.selector === 'shape').tagName = 'polygon';
// noinspection JSAnnotator
export class Parallelogram extends CustomNodeShape {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.Parallelogram',
            size: { width: 350, height: 125 },
            attrs: {
                ...super.defaults().attrs,
                shape: {
                    points: '50,0 350,0 300,125 0,125',
                    strokeWidth: 2,
                    stroke: 'black',
                },
                body: {
                    width: 'calc(w-110)',
                    height: 'calc(h-4)',
                    x: 60,
                    y: 2,
                    stroke: 'transparent',
                    magnet: true
                },
                bodySymbolLeft: {
                    ...super.defaults().attrs.bodySymbolLeft,
                    x: 'calc(0.08*w)',
                },
                bodySymbolRight: {
                    ...super.defaults().attrs.bodySymbolRight,
                    dx: 'calc(0.92*w)',
                },
            },
        };
    }
    markup = markup2;
}
const markup3 = _.cloneDeep(markup);
markup3.find(s => s.selector === 'shape').tagName = 'polygon';
// noinspection JSAnnotator
export class Trapezium extends CustomNodeShape {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.Trapezium',
            size: { width: 350, height: 125 },
            attrs: {
                ...super.defaults().attrs,
                shape: {
                    points: '25,0 325,0 350,125 0,125',
                    strokeWidth: 2,
                    stroke: 'black',
                },
                body: {
                    width: 'calc(w-104)',
                    height: 'calc(h-4)',
                    x: 60,
                    y: 2,
                    stroke: 'transparent',
                },
                operator: {
                    ...super.defaults().attrs.operator,
                    x: 'calc(w-64)',
                },
                bodySymbolLeft: {
                    ...super.defaults().attrs.bodySymbolLeft,
                    x: 'calc(0.08*w)',
                },
                bodySymbolRight: {
                    ...super.defaults().attrs.bodySymbolRight,
                    dx: 'calc(0.92*w)',
                },
                validityBadge: {
                    ...super.defaults().attrs.validityBadge,
                    cx: 'calc(0.13*w)',
                },
                validityText: {
                    ...super.defaults().attrs.validityText,
                    dx: 'calc(0.13*w)',
                },
            },
        };
    }
    markup = markup2;
}
// noinspection JSAnnotator
export class ReverseTrapezium extends CustomNodeShape {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.ReverseTrapezium',
            size: { width: 350, height: 125 },
            attrs: {
                ...super.defaults().attrs,
                shape: {
                    points: '0,0 350,0 325,125 25,125',
                    strokeWidth: 2,
                    stroke: 'black',
                },
                body: {
                    width: 'calc(w-104)',
                    height: 'calc(h-4)',
                    x: 60,
                    y: 2,
                    stroke: 'transparent',
                },
                operator: {
                    ...super.defaults().attrs.operator,
                    x: 'calc(w-64)',
                },
                bodySymbolLeft: {
                    ...super.defaults().attrs.bodySymbolLeft,
                    x: 'calc(0.08*w)',
                },
                bodySymbolRight: {
                    ...super.defaults().attrs.bodySymbolRight,
                    dx: 'calc(0.92*w)',
                },
                validityBadge: {
                    ...super.defaults().attrs.validityBadge,
                    cx: 'calc(0.11*w)',
                },
                validityText: {
                    ...super.defaults().attrs.validityText,
                    dx: 'calc(0.11*w)',
                },
            },
        };
    }
    markup = markup2;
}
// noinspection JSAnnotator
export class OutputBox extends CustomNodeShape {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.OutputBox',
            size: { width: 350, height: 125 },
            attrs: {
                ...super.defaults().attrs,
                shape: {
                    points: '25,0 325,0 350,62.5 325,125 25,125 0,62.5',
                    strokeWidth: 2,
                    stroke: 'black',
                },
                body: {
                    width: 'calc(w-104)',
                    height: 'calc(h-4)',
                    x: 60,
                    y: 2,
                    stroke: 'transparent',
                },
                operator: {
                    ...super.defaults().attrs.operator,
                    x: 'calc(w-64)',
                },
                bodySymbolLeft: {
                    ...super.defaults().attrs.bodySymbolLeft,
                    x: 'calc(0.08*w)',
                },
                bodySymbolRight: {
                    ...super.defaults().attrs.bodySymbolRight,
                    dx: 'calc(0.92*w)',
                },
                validityBadge: {
                    ...super.defaults().attrs.validityBadge,
                    cx: 'calc(0.1*w)',
                },
                validityText: {
                    ...super.defaults().attrs.validityText,
                    dx: 'calc(0.1*w)',
                },
            },
        };
    }
    markup = markup2;
}
// noinspection JSAnnotator
export class InputBox extends CustomNodeShape {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.InputBox',
            size: { width: 350, height: 125 },
            attrs: {
                ...super.defaults().attrs,
                shape: {
                    points: '0,0 350,0 325,62.5 350,125 0,125 25,62.5',
                    strokeWidth: 2,
                    stroke: 'black',
                },
                body: {
                    width: 'calc(w-104)',
                    height: 'calc(h-4)',
                    x: 60,
                    y: 2,
                    stroke: 'transparent',
                },
                operator: {
                    ...super.defaults().attrs.operator,
                    x: 'calc(w-64)',
                },
                bodySymbolLeft: {
                    ...super.defaults().attrs.bodySymbolLeft,
                    x: 'calc(0.08*w)',
                },
                bodySymbolRight: {
                    ...super.defaults().attrs.bodySymbolRight,
                    dx: 'calc(0.92*w)',
                },
                validityBadge: {
                    ...super.defaults().attrs.validityBadge,
                    cx: 'calc(0.1*w)',
                },
                validityText: {
                    ...super.defaults().attrs.validityText,
                    dx: 'calc(0.1*w)',
                },
            },
        };
    }
    markup = markup2;
}
// noinspection JSAnnotator
export class XRefLink extends joint.shapes.standard.Link {
    defaults() {
        return {
            ...super.defaults,
            type: 'komp.XRefLink',
        };
    }
}
Object.assign(shapes, {
    komp: {
        CustomNodeShape,
        Parallelogram,
        Trapezium,
        ReverseTrapezium,
        OutputBox,
        InputBox,
        XRefLink,
    },
});
export function createCustomShape(node, style = null, state = null) {
    let _style = style || behaviourNodeStyleDefinitions.find(d => d.title === node.type);
    const wrappedHeaderText = node && node.type !== 'FunctionNode' ? util.breakText(node?.display_cpt_name || '', { width: 200 }, { 'font-size': 16 }) : '';
    // use the first word of the node validity, lowercased, as the css class suffix
    let customBodyCss = buildNodeCss(node, _style);
    const options = {
        position: { x: 0, y: 0 },
        attrs: {
            bodyTextContent: {
                title: '',
                html: '',
            },
            body: {
                rx: _style?.radius || 0,
                ry: _style?.radius || 0,
                class: customBodyCss,
            },
            shape: {
                class: customBodyCss,
            },
            bodySymbolLeft: { text: node?.symbol_l || '' },
            bodySymbolRight: { text: node?.symbol_r || '' },
            header: {
                text: wrappedHeaderText,
            },
            operator: {
                html: state?.constants.behaviourOperators?.find(e => e.id === node?.operator)?.display || ''
            },
            validityBadge: {
                class: `bn-badge-${node?.validity.split(' ')[0].toLocaleLowerCase()}`,
                title: node?.validity,
            },
            validityText: {
                text: node?.issues.length,
                title: node?.validity,
            },
            probabilityBadge: {
                class: `bt-probability-badge`,
                title: 'Probability of this node being reached',
            },
            probabilityText: {
                text: node?.probability ? `P = ${node?.probability}` : '',
                title: 'Probability of this node being reached',
            },
        },
        siblingRank: node?.siblingRank || 0,
        id: node?.id || Math.random(),
    };
    const nodeType = node?.type || style?.title;
    // TODO: Find a use for a parallelogram
    // if (node.type === 'Event') {
    //   return new Parallelogram(options)
    // }
    let shape = null;
    if (nodeType === 'Selection') {
        shape = new Trapezium(options);
    }
    if (nodeType === 'GuardedEvent') {
        shape = new ReverseTrapezium(options);
    }
    if (nodeType === 'Output') {
        shape = new OutputBox(options);
    }
    if (nodeType === 'Input') {
        shape = new InputBox(options);
    }
    if (shape === null) {
        shape = new CustomNodeShape(options);
    }
    shape.attr('probabilityBadge/fill-opacity', 0);
    shape.attr('probabilityText/text', '');
    try {
        if (node) {
            const wrappedBodyText = util.breakText(node?.behaviour_name || '', { width: 275 }, { 'font-size': 12, 'font-weight': 'lighter', 'font-family': 'verdana', });
            if (node.probability && node.probability !== "1") {
                shape.attr('probabilityBadge/fill-opacity', 0.5);
                shape.attr('probabilityText/text', `P = ${node?.probability}`);
            }
            if (node.operator) {
                // operator link handler
                // this wouldn't get minified
                shape.attr('operator/onclick', `olh(event)`);
                shape.attr('operator/title', `Navigate to Reference`);
                shape.attr('operator/class', `bn-operator-icon`);
            }
            if (nodeType === 'FunctionNode') {
                shape.size({ width: 350, height: 85 });
                shape.attr('bodySymbolLeft/text', '');
                shape.attr('bodySymbolRight/text', '');
                // Disable issue Badge
                shape.attr('validityBadge/fill-opacity', 0);
                shape.attr('validityText/text', '');
            }
            else {
                shape.attr('bodyTextContent/title', wrappedBodyText);
            }
            shape.attr('bodyTextContent/html', createDisplayNameHtml(node));
        }
    }
    catch (e) {
        console.error();
    }
    return shape;
}
// Create links for x-refs and timing links
export function createXrefLink(source, target, colour = 'orange', label = '') {
    const opts = { anchor: {
            name: 'midSide',
            args: {
                rotate: true,
                padding: 2
            }
        } };
    const link2 = new XRefLink();
    if (source && target) {
        link2.source(source, opts);
        link2.target(target, opts);
    }
    //link2.router('manhattan', {maximumLoops: 70000,  maxAllowedDirectionChange: 10, excludeTypes: [], })
    link2.router('metro');
    link2.connector('rounded', { 'radius': 7 });
    if (label && label !== '') {
        link2.appendLabel({
            attrs: {
                text: {
                    text: label,
                    fill: colour,
                },
                rect: {
                    fill: 'black',
                },
            }
        });
    }
    link2.attr({
        line: {
            stroke: colour,
            strokeWidth: 2,
            strokeDasharray: '4 4',
        }
    });
    return link2;
}
