import vSelect from 'vue-select/dist/vue-select';
import Fuse from 'fuse.js';
import { ref, toRef, watch } from '@vue/composition-api';
import { useDebounceFn } from '@vueuse/core';
export default {
    components: { vSelect },
    props: {
        keys: { type: Array, default: () => ['name'] },
        elementId: { type: String, default: () => 'bn-search-dropdown' },
        displayProp: { type: String, default: () => 'name' },
        maxResults: { type: Number, default: () => 9 },
        searchList: Array,
        debounceMs: { type: Number, default: () => 100 },
    },
    setup(props, context) {
        const searchBox = ref(null);
        const searchTextRef = toRef(props, 'searchText');
        const searchListRef = toRef(props, 'searchList');
        const searchResults = ref([]);
        watch(searchListRef, (first, second) => {
            if (searchTextRef.value) {
                // The results have changed, so filter them again
                performSearch(searchTextRef.value);
            }
        }, { immediate: true });
        function focusSearchResult(result) {
            if (result) {
                context.emit('resultClicked', result);
            }
        }
        const debouncedSearch = useDebounceFn(loadingFn => {
            context.emit('searchy', searchTextRef.value);
            performSearch(searchTextRef.value);
            loadingFn(false);
        }, props.debounceMs);
        function searchInput(input, loading) {
            searchTextRef.value = input;
            if (searchTextRef.value.length < 1)
                return;
            loading(true);
            debouncedSearch(loading);
        }
        function performSearch(input) {
            const options = {
                includeScore: true,
                keys: props.keys,
            };
            const list = props.searchList;
            const fuse = new Fuse(list, options);
            const results = fuse.search(input);
            searchResults.value = results.filter((e, i) => i < props.maxResults).map(e => e.item).slice();
        }
        const OpenIndicator = {
            render: createElement => createElement('feather-icon', {
                props: { icon: 'SearchIcon' },
                style: 'transform: rotate(0) !important;',
            }),
        };
        function dropdownShouldOpen(vueSelect) {
            if (searchResults.value && searchResults.value.length > 0) {
                return vueSelect.open;
            }
            return vueSelect.search.length !== 0 && vueSelect.open;
        }
        return {
            // search
            searchTextRef,
            searchResults,
            focusSearchResult,
            searchInput,
            searchBox,
            OpenIndicator,
            dropdownShouldOpen,
        };
    },
};
