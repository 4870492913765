<template>
  <b-modal
    id="show-tests-modal"
    title="Display Test Results"
    size="lg"
    :ok-title="creating ? 'Re-drawing the view...' : 'Colour Based on Tests'"
    :ok-disabled="creating"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="getData"
    @ok="submitFunction"
  >
    <div>
      <b-form-group
        label="Show Tests for the selected Build Configurations"
        label-for="config-select"
      >
        <b-form-select
          id="config-select"
          v-model="configs"
          :options="config_options"
          :select-size="7"
          multiple
        >
          <template #first>
            <b-form-select-option value="" aria-selected="true">
              -- All --
            </b-form-select-option>
          </template>

        </b-form-select>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import axiosIns from '@/libs/axios'

export default {
  name: 'ShowTestsModal',
  data: () => ({
    configs: [''],
    allConfigs: [],
    creating: false,
  }),
  computed: {
    ...mapState({
      selected_bt: state => state.behaviours.selectedBehaviourTree,
    }),
    config_options() {
      return this.allConfigs.map(x => ({
        value: x.id,
        html: x.name,
      }))
    },
  },
  methods: {
    getData() {
      const params = { model: this.$store.state.model.id }
      this.$http
        .get('/api/v2/domain_model/get_build_configurations', { params })
        .then(({ data }) => {
          this.allConfigs = data
        })
    },
    submitFunction(evt) {
      evt.preventDefault()
      this.creating = true
      const filtered = this.configs.filter(x => x !== '')
      const params = {
        model: this.$store.state.model.id,
        configs: { filter: filtered },
      }
      this.$store.dispatch('behaviours/selectConfigs', filtered)
      this.$http
        .get(`/api/v2/behaviour/get_testedness/${this.selected_bt.id}`, { params })
        .then(({ data }) => {
          this.creating = false
          this.$bvModal.hide('show-tests-modal')
          this.$emit('testedness', data)
        })
        .catch(() => {
          this.creating = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.modal-footer-behaviour {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
